import React from 'react'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import MyLinks from './pages/MyLinks'
import * as connection from './api/connection'
import { useQuery } from 'react-query'
import SignIn from './pages/SignIn';
import CheckEmail from './pages/CheckEmail'
import AppPrivacy from './pages/AppPrivacy';
import AppTermsConditions from './pages/appTermsConditions'

function App () {
  const connectionToken = useQuery('connectionToken', connection.getConnectionToken)
  let mainPage
  if (connectionToken?.data) {
    mainPage= <MyLinks/>
  } else {
    mainPage = <Home/>
  }
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/appTermsConditions" element={<AppTermsConditions/>}/>
        <Route path="/appprivacy" element={<AppPrivacy />} />
          <Route path="/" element={mainPage}/>
        <Route path="/login" element={<Login />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/checkEmail" element={<CheckEmail />}/>
        <Route path="/mylinks" element={<MyLinks/>}/>
      </Routes>
      </BrowserRouter>
  )
}

export default App
