import React from 'react'
import { Link } from 'react-router-dom'
import AndroidIMG from '../assets/androidWhite.svg'
import playStore from '../assets/google-play-badge.png'
import AppStore from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import LinksLogoLong from '../assets/Linkk_Long_Logo.png'
function Home() {




  return (
    <div>
      <div className='title'>
        <img src={LinksLogoLong} alt='Links' width={250}/>
      </div>
      
      <div className='introduction'>
        
        <button className='button-addExtension umami--click--install-extention' onClick={() => { window.open('https://chrome.google.com/webstore/detail/links/pcahkifbhckabcdeepognmaobbmjldbc') }}>
          Add Links extension
        </button>
      </div>
      <div className='buttonConnection'>
        <Link to="/login">
      <button id="LogInBtn" className="epiqueButton buttonAuth umami--click--LogIn-button" ><span>Log in</span></button>
        </Link>
        <Link to="/signin">
      <button id="SignInBtn" className="epiqueButton buttonAuth umami--click--signup-button"><span>Sign up</span></button>
        </Link>
      </div>
      {/* <div className='introduction'>
        <button className='button-installAPK' onClick={() => { window.open('/links.apk') }}>
          <div className='insideButton'>
            <div>
              <img src={AndroidIMG} width={20} alt='Android' />
            </div>
          <div>Download the APK to install it on your Android phone</div>
          </div>
        </button>
      </div> */}
     
      <div className='storesButton'>
        <div className='storedivButton'>
          <button className='storeButtonAndroid umami--click--playStore' onClick={()=>{ window.open('https://play.google.com/store/apps/details?id=com.linksmobile')}}>
          <img src={playStore} alt='Google play Store' height={57}/>
          </button>
           <div className='floating-Android'>
            plan on 22nd of may
          </div>
        </div>
        <div className='storedivButton'>
          <button className='storeButton umami--click--appStore'>
          <img src={AppStore} alt= 'Apple play store' width={119.664} height={40}/>
          </button>
           <div className='floating-Android'>
            plan mid june
          </div>
        </div>
      </div>
      <div className='presentation-youtube'>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/JKyYaEs_xLk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    
  )
}

export default Home