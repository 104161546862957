import { useMutation, useQuery, useQueryClient } from "react-query"
import * as linksAPI from '../api/links'

export default function useUpdateLink(linkId, labelId:string) {
  const queryClient = useQueryClient()
  const connectionToken = useQuery('connectionToken')
  const links = useQuery(['mylinks', connectionToken.data, labelId], linksAPI.getAll)

  return useMutation(linksAPI.update, {
    onSuccess: data => {
      console.log('data', data)
      const newData= links.data.map((element) => {
      if (element._id === linkId) {
        return data
      }
      return element
    })
     queryClient.setQueryData(['mylinks', connectionToken.data,labelId], newData)
    }
  })
}