import * as server from '../constant/server'


export async function getConnectionToken () {
  try {
    const result = localStorage.getItem('connectionToken')
    return Promise.resolve(result)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function connection (loginInfo :{ username: string, password: string }) {
  try {
    const dataToSend = loginInfo
    const response = await fetch(`${server.url}/user/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })
    const result = await response.json()
    await localStorage.setItem('connectionToken',result.connectionToken)
    return Promise.resolve(result.connectionToken)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function register(data) {
  try {
    const dataToSend = {
      username: data.userName,
      password: data.password
    }
    console.log('server : ', server)
    const response = await fetch(`${server.url}/user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
    const result = await response.json()
    await localStorage.setItem('user',result._id )
    return Promise.resolve(result)
  } catch (e) {
    console.error(e)
    return Promise.reject(e)
  }
}

export async function checkEmail(data) {
  try {
    const dataToSend = {
      userId: data._id,
      token : data.token
    }
    const response = await fetch(`${server.url}/user/validateEmail`, {
         method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
    const result = await response.json()
    await localStorage.setItem('connectionToken',result.connectionToken)
    return Promise.resolve(result)
  } catch (e) {
    console.error(e)
    return Promise.reject(e)
  }
}
