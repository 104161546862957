import React, { useState } from 'react'
import { useQuery } from 'react-query'
import * as linkApi from '../api/links'
import CardLink from '../components/CardLink'
import useGetLabels from './../hooks/useGetLabels';



function MyLinks() {
  const [labelId, setLabelId] = useState('All')
  const connectionToken = useQuery('connectionToken')
  const  myLinks = useQuery(['mylinks', connectionToken.data, labelId], linkApi.getAll, { refetchInterval: 10000 })
  
  
  function selectLabel(newLabelId:string) {
    if (newLabelId === labelId) {
      setLabelId('All')
    } else {
      setLabelId(newLabelId)
    }
  }
  //console.log('result', result)
  if (myLinks.status === 'success') {
      //console.log('result.data', result.data)
      const listLinks = myLinks.data.map((links:object) => (
        <CardLink link={links} onSelectLabel={selectLabel} labelId={labelId} key={links._id}/>
      )
      )
    return (
      <div className='myLinks-container'>
        {listLinks}
     </div>
    )
  }
  return (
    <div></div>
  )

}

export default MyLinks