
import { useQueryClient, useMutation } from 'react-query';
import * as connection from '../api/connection';

export default function useSignIn() {
  const queryClient = useQueryClient()
  console.log('ici')
  return useMutation(connection.register, {
    onSuccess: data => {
      console.log('data from connection.register', data)
      const newData = data
      queryClient.setQueryData(['user'], newData)
    }
  })
}