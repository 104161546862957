import React, { useState } from 'react'
import './EditableField.css'

function EditableField({ onSave, value, onClick }) {
  const [edit, setEdit] = useState(false)
  const [newValue, setNewValue] = useState(value)
  // console.log(newValue)
  function handleSubmit(event) {
    event.preventDefault()
    onSave(newValue)
  }

  function handleChange(event) {
    if (event.target.name === 'title') {
      setNewValue(event.target.value)
    }
  }



  if (edit === true) {
    return (
      <div className='className="EditableField-container'>
        <form onSubmit={handleSubmit}>
          <div className='EditableField-link-container'>
            
            <textarea rows={4} name='title' value={newValue} className='EditableField-input' onChange={handleChange}/>
          </div>
          <div className='EditableField-SaveCancel'>
            <div className='EditableField-EditButton-container'>
              <button className='EditableField-EditButton umami--click--saveLinkTitle'
                onClick={() => {
                  onSave(newValue)
                  setEdit(false)
                }}>
                Save title
              </button>
            </div>
            <div className='EditableField-EditButton-container'>
              <button className='EditableField-EditButton umami--click--cancelEditLinkTitle'
                onClick={() => {
                  setEdit(false)
                  setNewValue(value)
                }}>
                Cancel
              </button>
              </div>
            </div>
          </form>
        </div>
    )
  }
  
  return (
    <div className="EditableField-container">
      <div className='EditableField-link-container'>
        <button onClick={onClick} className="EditableField-link umami--click--openLink">
          <span className='EditableField-text'>{value}</span>
        </button>
      </div>
      <div className='EditableField-EditButton-container'>
        <button className='EditableField-EditButton umami--click--editLinkTitle'
          onClick={() => { setEdit(true) }}>
        Edit page title
        </button>
      </div>
    </div>
  )
}

export default EditableField