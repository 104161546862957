export const options = [
  "#E9EFC0",
  "#B4E197",
  "#83BD75",
  "#4E944F",
  "#DEB6AB",
  "#AC7D88",
  "#85586F",
  "#AB46D2",
  "#FF6FB5",
  "#55D8C1",
  "#FCF69C",
  "#251D3A",
  "#2A2550",
  "#E04D01",
  "#FF7700",
  "#F32424",
  "#764AF1",
  "white",

]