import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useNavigate } from "react-router-dom"
import * as connection from '../api/connection'
import useLogin from '../hooks/useLogin'

function Login () {
  const [loginForm, setLoginForm] = useState({})

  function handleChange (event:any) {
    const name = event.target.name
    const value = event.target.value
    setLoginForm(values => ({ ...values, [name]: value }))
  }
  const connectionToken = useQuery('connectionToken', connection.getConnectionToken)

  const connectionQuery = useLogin()
  const navigate = useNavigate();
  
  if (connectionToken.data || connectionQuery.data) {
     navigate('/')
  } 
  
  function handleSubmit (event) {
    event.preventDefault()
    console.log('submit')
    connectionQuery.mutate(loginForm)
  }

  return (
    <div>
      <div className='title'>
        <h1>
          Links
        </h1>
      </div>
      <div className='loginForm'>
        <form onSubmit={handleSubmit}>
          <div className='line'>
        <input id="usernameFld" type="email" name="username" value={loginForm.username} placeholder="user@exemple.com" onChange={handleChange}/>
          </div>
          <div className='line'>
        <input id="passwordFld" type="password" name="password" value={loginForm.password} placeholder="********" pattern=".{8,}" onChange={handleChange}/>
          </div>
        <input type="submit" id="connectBtn" className="epiqueButton" value="Log in"/>
        </form>
      </div>

    </div>
  )
}

export default Login


