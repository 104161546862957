//local

//export const url = "http://192.168.31.103:4000"



//dev
// export const url = "https://api.linksdev.coolify.epique.xyz"
// export const url = "https://7bd7-217-67-149-88.ngrok-free.app"
// export const url = "https://devlinksapi.apps2.epique.xyz"

//Production
export const url = "https://api.links.epique.xyz"

