import React from 'react'
import * as server from '../constant/server'
import './cardLink.css'
//@ts-ignore
import EasyEdit, {Types} from 'react-easy-edit';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import * as linksAPI from '../api/links'
import Labels from './Labels';
import useAddLabel from './../hooks/useAddLabel';
import useRemoveLabel from './../hooks/useRemoveLabel';
import useUpdateLink from './../hooks/useUpdateLink';
import useDeleteLink from './../hooks/useDeleteLink';
import EditableField from './EditableField';



function CardLink({ link, onSelectLabel, labelId }) {
  const creationDate = new Date(link.creationDate)
  
  
  const formattedCreationDate = creationDate.toLocaleDateString(navigator.language,{ day:'2-digit', month:'long', year:'numeric', hour:'2-digit', minute:'numeric'})
 
  const linkMutation = useUpdateLink(link._id, labelId)
  const linkAddLabelMutation = useAddLabel(link._id, labelId)
  const linkRemoveLabelMutation = useRemoveLabel(link._id, labelId)
  const linkDeleteMutation = useDeleteLink(link._id, labelId)
  function save(value: String) {
    linkMutation.mutate({
      linkId: link._id,
      title: value
    })
    //console.log(value)
  }

  function cancel() {
    console.log('cancel')
  }

  function openLink() {
    window.open(link.url, '_blank')
  }

  function addLabel(newLabel) {
    linkAddLabelMutation.mutate({
      linkId: link._id,
      name: newLabel.name,
      color:newLabel.color
    })
    //console.log('add Label', newLabel)
  }

  function removeLabel(labelId) {
    // console.log('remove label', labelId)
    linkRemoveLabelMutation.mutate({
      linkId: link._id,
      labelId: labelId
    })
  }

  function deleteLink() {
    linkDeleteMutation.mutate({
      linkId : link._id
    })
  }


  return (
    <div key={link._id} className='Card-link'>
      <div className='Card-link-element'>
        <div className='Card-link-data'>
          <EditableField
            onClick={openLink}
            value={link.title}
            onSave={save}      />
          {/* <button className='smallButton' onClick={openLink} >
            <img src="icons8_open_in_browser.svg" height={20} alt="open" />
          </button> */}
        </div>
      </div>
      
      <div className='screenShot'>
        {link.screenShot &&
          (<a href={`${server.url}/screenShot/${link.screenShot}`} target="_blank" rel="noreferrer" className='umami--click--openScreenShot' > <img src={`${server.url}/screenShotCropResized/${link.screenShot}`} alt='screenshot' /></a>)}
      </div>
      <div className='PDF'>
        {link.pdf && (
          <a href={`${server.url}/PDF/${link.pdf}`} target="_blank" rel="noreferrer" className='umami--click--openPDF'><img src="/icons8_pdf.svg" alt="download PDF" height={40}/></a>
        )}
      </div>
      <div className='Card-link-element'>
        <div className='Card-link-date'>added on :<br/>{formattedCreationDate}</div>
      </div>
      <div className='labelContainer'>
        <Labels addLabel={addLabel} onRemove={removeLabel} labels={link.labels} onSelectLabel={onSelectLabel}/>
      </div>
       <div className='deleteContainer'>
        <button type='button' onClick={() => { deleteLink() }} className="smallButton umami--click--deleteLink">
          <img src="/icons8_delete_red.svg" height={15} alt="Delete Link"/><span>Delete this link</span>
          </button>
      </div>
    </div>
  )
}

export default CardLink