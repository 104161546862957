import { useMutation, useQueryClient } from "react-query";
import * as connection from '../api/connection';

export default function useUserCheckEmail(){
  const queryClient = useQueryClient()
  return useMutation(connection.checkEmail, {
    onSuccess: data => {
      queryClient.setQueryData(['connectionToken'], data)
    }
  })
}