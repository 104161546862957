import { useQuery, useMutation, useQueryClient } from 'react-query'
import * as connection from '../api/connection'

export default function useLogin() {
   const queryClient = useQueryClient()
  return useMutation(connection.connection, {
    onSuccess: data => {
      console.log(data)
      queryClient.setQueryData(['connectionToken'], data)
    }
  })
}