import { useMutation, useQuery, useQueryClient } from "react-query"
import * as linksAPI from '../api/links'

export default function useRemoveLabel(linkId:string,labelId:string)  {
  const queryClient = useQueryClient()
  const connectionToken = useQuery('connectionToken')
  const links = useQuery(['mylinks', connectionToken.data, labelId], linksAPI.getAll)
  return useMutation(linksAPI.removeLabel, {
    onSuccess: data => {
     
      const newData = links.data.map((element) => {
        if (element._id === linkId) {
          return data
        }
        return element
      })
      console.log('newdata', newData)
      queryClient.setQueryData(['mylinks', connectionToken.data, labelId], newData)
    }
  })

}