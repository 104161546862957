
import * as server from '../constant/server'


export async function getAll(query) {
  
  try {
    let url:string
    const init = {
      method: 'GET',
      headers: {
        "Access-Control-Max-Age": 600,
        authorization: `Bearer ${query.queryKey[1]}`,
        'Content-Type': 'application/json',
      },
    }
    if (query.queryKey[2]===undefined) return Promise.resolve()
    if (query.queryKey[2] === 'All' ||query.queryKey[2]===undefined) {
      url = `${server.url}/links`
    } else {
      url = `${server.url}/links/label/${query.queryKey[2]}`
    }
    const result = await fetch(url,init)
    if (!result.ok) {
      return Promise.reject('Network response was not OK')
    }
    return Promise.resolve(result.json())
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function update(newData: any) {
  try {
      const init = {
      method: 'PUT',
        headers: {
        "Access-Control-Max-Age": 600,
        authorization: `Bearer ${localStorage.getItem('connectionToken')}`,
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        ...newData
      })
    }
    const result = await fetch(`${server.url}/link/${newData.linkId}`, init)
    return Promise.resolve(result.json())
    
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function addLabel(newData: any) {
  try {
    
      const init = {
      method: 'POST',
        headers: {
        "Access-Control-Max-Age": 600,
        authorization: `Bearer ${localStorage.getItem('connectionToken')}`,
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        ...newData
      })
    }
    const result = await fetch(`${server.url}/link/${newData.linkId}/label/`, init)
    return Promise.resolve(result.json())
    
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function removeLabel(newData: any) {
  
  try {
      const init = {
      method: 'DELETE',
        headers: {
        "Access-Control-Max-Age": 600,
        authorization: `Bearer ${localStorage.getItem('connectionToken')}`,
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        ...newData
      })
    }
    const result = await fetch(`${server.url}/link/${newData.linkId}/label/${newData.labelId}`, init)
    //console.log('result fetch remove labael' , await result.json())
    const resultToSend = await result.json()
    return Promise.resolve(await resultToSend)
    
  } catch (e) {
    console.error('error remove label', e)
    return Promise.reject(e)
  }
}

export async function deleteLink(newData: any) {
  console.log('newData', newData)
  try {
      const init = {
      method: 'DELETE',
        headers: {
        "Access-Control-Max-Age": 600,
        authorization: `Bearer ${localStorage.getItem('connectionToken')}`,
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        ...newData
      })
    }
    const result = await fetch(`${server.url}/link/${newData.linkId}`, init)
    return Promise.resolve(result)
    
  } catch (e) {
    return Promise.reject(e)
  }
}


export async function getLabels(query) {
  console.log('queryKey', query.queryKey)
  try {
    const init = {
      method: 'GET',
      headers: {
        "Access-Control-Max-Age": 600,
        authorization: `Bearer ${query.queryKey[1]}`,
        'Content-Type': 'application/json',
      },
    }
    const result = await fetch(`${server.url}/labels`,init)
    if (!result.ok) {
      return Promise.reject('Network response was not OK')
    }
    return Promise.resolve(result.json())
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function getLabelId(query) {
  return 'All'
}