import { useMutation, useQuery, useQueryClient } from "react-query"
import * as linksAPI from '../api/links'

export default function useAddLabel(linkId, labelId:string)  {
  //console.log('data hook', linkId)
  const queryClient = useQueryClient()
  const connectionToken = useQuery('connectionToken')
  const links = useQuery(['mylinks', connectionToken.data, labelId], linksAPI.getAll)
  return useMutation(linksAPI.addLabel, {
    onSuccess: data => {
      console.log('addlabel data', data)
      console.log(linkId)
      const newData = links.data.map((element) => {
        if (element._id === linkId) {
          return data
        }
        return element
      })
      console.log('addLabel newData ', newData)
      queryClient.setQueryData(['mylinks', connectionToken.data, labelId], newData)
    }
  })

}

