import { useMutation, useQuery, useQueryClient } from "react-query"
import * as linksAPI from '../api/links'

export default function useDeleteLink(linkId:string, labelId:string)  {
  const queryClient = useQueryClient()
  const connectionToken = useQuery('connectionToken')
  const links = useQuery(['mylinks', connectionToken.data, labelId], linksAPI.getAll)
  return useMutation(linksAPI.deleteLink, {
    onSuccess: data => {
      console.log('data receive', data)
      const newData = links.data.filter((element)=>element._id!=linkId)
      console.log('newdata', newData)
      queryClient.setQueryData(['mylinks', connectionToken.data, labelId], newData)
    }
  })

}