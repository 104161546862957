import React from 'react'
import * as colors from '../constant/colors'
import './ColorSelector.css'
function Color({ color, onSelect, selected }) {
  if (selected) {
    return (
      <button type="button" className='colorselector-color-square colorselector-color-square-selected' style={{ "backgroundColor": color }} onClick={() => {
        onSelect(color)
      }} />
    )
  }
  return (
    <button type="button" className='colorselector-color-square' style={{ "backgroundColor": color }} onClick={() => {
      onSelect(color)
    }} />
  )
}
function ColorSelector({onSelect, selectedColor}) {
  return (
    <div className='colorselector-color-container'>
      {colors.options.map((color) => {
        if (color === selectedColor) {
          return (
            <Color color={color} onSelect={onSelect} selected/>
          )
        }
        return (
        <Color color={color} onSelect={onSelect}/>
      )})}
    </div>
  )
}

export default ColorSelector